<template>
  <div class="py-8 space-y-6 max-w-3xl mx-auto">
    <div class="flex flex-row justify-between items-center content-center">
      <p class="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900">
        Welcome!
      </p>
      <button
        type="button"
        class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        v-on:click="joinTeamModal = !joinTeamModal"
      >
        Join Existing Team
      </button>
    </div>

    <nav aria-label="Progress">
      <ol
        class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 bg-white"
      >
        <li
          v-for="(step, stepIdx) in steps"
          :key="step.name"
          class="relative md:flex-1 md:flex"
        >
          <router-link
            v-if="getCurrentVal() > step.val"
            :to="{ name: step.routeName }"
            class="group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-800"
              >
                <svg
                  class="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            v-else-if="getCurrentVal() === step.val"
            :to="{ name: step.routeName }"
            class="px-6 py-4  flex items-center text-sm font-medium"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full"
            >
              <span class="text-blue-600">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-blue-600">{{
              step.name
            }}</span>
          </router-link>
          <router-link
            v-else
            :to="{ name: step.routeName }"
            class="group flex items-center"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
              >
                <span class="text-gray-500 group-hover:text-gray-900">{{
                  step.id
                }}</span>
              </span>
              <span
                class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900"
                >{{ step.name }}</span
              >
            </span>
          </router-link>
          <template v-if="stepIdx !== steps.length - 1">
            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
    <div>
      <router-view></router-view>      
    </div>
    <Modal
      :open="joinTeamModal"
      title="Join a Team"
      size="large"
      modalIcon="fad fa-user-plus"
      modalIconColor="bg-blue-100 text-blue-700"
      :showEscapeButton="true"
      :showAcceptButton="true"
      acceptButtonText="Join Team"
      @close="joinTeamModal = false"
      ref="modal"
    >
      <div class="text-center space-y-4">
        <p>You can join an existing team by entering the invite code below.</p>
        <input type="text" class="focus:ring-blue-500 focus:border-blue-500 block text-2xl border-gray-300 rounded-md mx-auto text-center">
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import TextLoader from "@/screens/App/General/Loaders/TextLoader";
import Modal from "@/components/Modal";

const steps = [
  {
    id: "01",
    name: "Team Setup",
    routeName: "AppOnboardTeam",
    val: 1,
  },
  {
    id: "02",
    name: "Billing",
    routeName: "AppOnboardBilling",
    val: 2,
  },
  {
    id: "03",
    name: "Finalize",
    routeName: "AppOnboardFinalize",
    val: 3,
  },
];

export default {
  name: "AppOnboardingLayout",
  data() {
    return {
      steps: steps,
      joinTeamModal: false
    };
  },
  components: { TextLoader, Modal },
  computed: {
    ...mapState("auth", {
      teams: (state) => state.teams,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      loadingTeamData: (state) => state.loadingTeamData,
    }),
  },
  mounted() {
    console.log(this.$route.name);
  },
  methods: {
    getCurrentVal() {
      return steps.filter((x) => x.routeName == this.$route.name)[0].val;
    },
  },
};
</script>
